// noinspection ES6UnusedImports
import {scrollend} from 'scrollyfills';
import axios from 'axios';

/**
 * @enum {string}
 */
const RESIZE_STATE = {
    UP: 'UP',
    DOWN: 'DOWN'
}

const Platinrace = {
    /**
     * @var {NodeList}
     */
    navButtons: [],
    stopScrollHandler: false,
    /**
     * @var {HTMLDivElement|undefined}
     */
    container: undefined,
    /**
     * @var {HTMLButtonElement|undefined}
     */
    resizeUpBtn: undefined,
    /**
     * @var {HTMLButtonElement|undefined}
     */
    resizeDownBtn: undefined,
    /**
     * @var {HTMLAudioElement|undefined}
     */
    platinraceAudio: undefined,

    init(secondPage = false) {

        this.platinraceAudio = document.getElementById('platinrace_audio');
        this.platinraceAudio.load();

        this.container = document.getElementById('container');

        // Jackpots
        let jackpotEl = document.getElementById('jackpot-amount');
        if (jackpotEl) {
            let amount = parseFloat(jackpotEl.dataset.amount);
            let growthSpeed = parseFloat(jackpotEl.dataset.growthSpeed);
            let updatedAt = new Date(Date.parse(jackpotEl.dataset.updatedAt));
            let now = new Date();
            let diffSeconds = Math.floor((now - updatedAt) / 1000);
            amount += growthSpeed * diffSeconds;
            jackpotEl.textContent = amount.toFixed(2);
            setInterval(function () {
                amount += growthSpeed;
                jackpotEl.textContent = (Math.floor(amount * 100) / 100).toFixed(2);
            }, 1000);
            if (secondPage) {
                setInterval(() => {
                    axios.get('/game/secondpage/268').then((response) => {
                        amount = response.data.jackpot;
                    });
                }, 30 * 1000);
            }
        }

        // Scrolling
        let nav = document.querySelector('nav ul');
        if (nav.scrollWidth > nav.clientWidth) {
            nav.classList.add('pb-2');
        }
        if (nav.scrollWidth <= nav.clientWidth) {
            nav.classList.add('justify-center');
        }

        this.navButtons = document.querySelectorAll('nav button');
        this.container = document.getElementById('container');
        this.platinraceWrapper = document.getElementById('platinrace_wrapper');

        this.navButtons.forEach(navButton => {
            navButton.addEventListener('click', event => {
                this.menuClickHandler(event);
                this.platinraceAudio.play();
            });
        });

        // Скролл на кнопках
        let checkScroll = true;
        const platinraceGamesLeftBtn = document.getElementById('platinrace-nav_scroll_left');
        const platinraceGamesRightBtn = document.getElementById('platinrace-nav_scroll_right');

        platinraceGamesLeftBtn.addEventListener('click', () => {
            this.platinraceAudio.play();
            //скролл в лево большой экран
            if (checkScroll === true) {
                this.container.scrollBy({
                    left: -(this.container.getBoundingClientRect().width + parseInt(document.documentElement.style.getPropertyValue('--image-gap'))), // container width + gap
                    top: 0,
                    behavior: 'smooth'
                });
            }
        });

        platinraceGamesRightBtn.addEventListener('click', () => {
            this.platinraceAudio.play();
            //скролл в право большой экран
            if (checkScroll === true) {
                this.container.scrollBy({
                    left: this.container.getBoundingClientRect().width + parseInt(document.documentElement.style.getPropertyValue('--image-gap')),
                    top: 0,
                    behavior: 'smooth'
                });
            }
        });

        this.container.addEventListener('scroll', () => {
            checkScroll = false;
            setTimeout(() => (checkScroll = true), 1000);
        });

        this.container.addEventListener('scrollend', () => {
            checkScroll = true;
        });

        // resize
        this.resizeUpBtn = document.getElementById('platinrace_size_up');
        this.resizeDownBtn = document.getElementById('platinrace_size_down');

        const gamesCount = this.container.querySelectorAll('.platinrace_card').length;
        const savedResizeState = localStorage.getItem('resize') ?? (gamesCount < 8 ? RESIZE_STATE.UP : RESIZE_STATE.DOWN);
        switch (savedResizeState) {
            case RESIZE_STATE.UP:
                this.resizeView(savedResizeState);
                this.showOrHideNavButtons(savedResizeState)
                break;
            case RESIZE_STATE.DOWN:
                this.showOrHideNavButtons(savedResizeState)
                break;
        }

        this.resizeUpBtn.addEventListener('click', () => {
            this.resizeView(RESIZE_STATE.UP)
            this.showOrHideNavButtons(RESIZE_STATE.UP)
        });

        this.resizeDownBtn.addEventListener('click', () => {
            this.resizeView(RESIZE_STATE.DOWN)
            this.showOrHideNavButtons(RESIZE_STATE.DOWN)
        });

        // подсвечиваем активную группу игр в меню при скролле
        const groupList = document.querySelectorAll('.platinrace_card__title');

        const activeCategoryObserver = new IntersectionObserver(
            (entries, _) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        let activeGroup = entry.target.dataset.group

                        for (let i = 0; i < this.navButtons.length; i++) {
                            if (this.navButtons[i].dataset.group === activeGroup) {
                                this.highlightNavButton(i);
                                break
                            }
                        }
                    }
                })
            },
            {
                root: document.getElementById('container'),
                rootMargin: '0px -88% 0px 0px'
            }
        );

        groupList.forEach(group => activeCategoryObserver.observe(group));
    },

    menuClickHandler(event) {
        let navDataSet = event.target.dataset.group;
        let anchor = document.querySelector(`h1[data-group="${navDataSet}"]`);
        setTimeout(() => {
            for (let i = 0; i < this.navButtons.length; i++) {
                if (this.navButtons[i] === event.target) {
                    this.highlightNavButton(i);
                    break;
                }
            }
        }, 800);

        if (!this.platinraceWrapper.classList.contains('platinrace-resize')) {
            this.container.scrollTo({
                left: anchor.parentElement.offsetLeft,
                behavior: 'smooth'
            });
        } else {
            this.container.scrollTo({
                left: anchor.parentElement.offsetLeft,
                behavior: 'smooth'
            });
        }
    },

    highlightNavButton(index) {
        this.navButtons[index].classList.remove('text-[#BFBFBF]');
        this.navButtons[index].classList.remove('border-b-transparent');
        this.navButtons[index].classList.add('text-[#6AF1FF]');
        this.navButtons[index].classList.add('border-b-[#6AF1FF]');
        this.navButtons.forEach((_, i) => {
            if (i !== index) {
                this.navButtons[i].classList.add('border-b-transparent');
                this.navButtons[i].classList.add('text-[#BFBFBF]');
                this.navButtons[i].classList.remove('text-[#6AF1FF]');
                this.navButtons[i].classList.remove('border-b-[#6AF1FF]');
            }
        });
    },

    /**
     * @param {string} newState
     */
    resizeView(newState) {
        this.platinraceAudio.play();
        if (newState === RESIZE_STATE.UP) {
            this.resizeUpBtn.classList.add('hidden');
            this.resizeDownBtn.classList.remove('hidden');
            this.platinraceWrapper.classList.add('platinrace-resize');
        } else {
            this.resizeDownBtn.classList.add('hidden');
            this.resizeUpBtn.classList.remove('hidden');
            this.platinraceWrapper.classList.remove('platinrace-resize');
        }

        this.container.scrollTo({
            left: 0,
            top: 0
        });

        localStorage.setItem('resize', newState);
    },

    /**
     * @param {string} resizeState
     */
    showOrHideNavButtons(resizeState) {
        const gamesCount = this.container.querySelectorAll('.platinrace_card').length
        const navPanel = document.querySelector('.platinrace-nav');
        if (resizeState === RESIZE_STATE.DOWN) {
            document.documentElement.style.setProperty('--image-count', '4')
            document.documentElement.style.setProperty('--image-max-width', '200px')
            document.documentElement.style.setProperty('--image-gap', '60px')
            if (gamesCount <= 8) {
                navPanel.classList.add('hidden')
            } else {
                navPanel.classList.remove('hidden')
            }
        } else {
            document.documentElement.style.setProperty('--image-count', '3')
            document.documentElement.style.setProperty('--image-max-width', '280px')
            document.documentElement.style.setProperty('--image-gap', '116px')
            if (gamesCount <= 3) {
                navPanel.classList.add('hidden')
            } else {
                navPanel.classList.remove('hidden')
            }
        }
    }
};

export default Platinrace;
